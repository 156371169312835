<template>
  <div class="general-container">
    <the-navbar :anonymous="true" />
    <not-found />
  </div>
</template>

<script>
import NotFound from '@/components/NotFound.vue'
import TheNavbar from '@/components/TheNavbar.vue'

export default {
  name: 'NotFoundPage',
  components: {
    NotFound,
    TheNavbar
  }
}
</script>
