<template>
  <centered-image-and-text-module
    image="not-found.png"
    title="¡La página no está disponible!"
    button-text="Ir a Relevant"
    button-link="https://joinrelevant.com"
  >
    Esta página o sitio no esta disponible momentáneamente.<br>
    Encontra mas información sobre nuestros productos, servicios y beneficios.
  </centered-image-and-text-module>
</template>

<script>
import CenteredImageAndTextModule from '@/components/CenteredImageAndTextModule.vue'

export default {
  name: 'NotFound',
  components: {
    CenteredImageAndTextModule
  }
}
</script>
